html.light {
  --toastify-color-info: var(--bs-primary);
  --toastify-color-success: var(--bs-success);
  --toastify-color-warning: var(--bs-warning);
  --toastify-color-error: var(--bs-danger);
}

html.dark {
  --toastify-color-info: var(--bs-primary-alt);
  --toastify-color-success: var(--bs-success-alt);
  --toastify-color-warning: var(--bs-warning-alt);
  --toastify-color-error: var(--bs-danger-alt);
  --toastify-color-dark: #3b3b3b;
}

body {
  background-color: #d1d1d1;
}

html.dark body {
  background-color: #090909;
}

html.dark .navbar {
  background-color: #3b3b3b !important;
}

.navbar {
  background-color: #bbbbbb !important;
}

.content {
  height: calc(100vh - 69px);
  background-color: #ebebebd1;
  overflow: hidden;
}

html.dark .content {
  background-color: #151515e6;
}

.cm-editor {
  font-size: 0.815rem !important;
  flex-grow: 1;
  border-radius: 0.3rem;
}

html.dark .cm-gutters {
  border-right: 1px solid #ffffff38;
}

#analysis-block {
  overflow-y: hidden;
}

#problems-block {
  overflow-y: auto;
}

#editor-wrapper {
  height: calc(100% - 86px);
}

.cm-editor {
  height: 100%;
}

.card-container {
  overflow-y: auto;
}

.card-container .card:last-child {
  margin-bottom: 0px !important;
}

.problem button {
  border: none !important;
  padding: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.problem button.feedback {
  border: 1px solid !important;
}

.problem svg {
  width: 20px;
  height: 20px;
}

.tiny-text {
  font-size: xx-small;
}

.problem.active {
  border-radius: 0.25rem !important;
  outline: 1px solid #0003;
}

html.dark .problem.active {
  outline-color: #fff6;
}

.explCollapseButton.noExpl,
.explCollapseButton.noExpl:hover {
  background-color: transparent !important;
}

html.light .explCollapseButton.noExpl:hover {
  color: #6c757d;
}

html.dark .explCollapseButton.noExpl {
  color: transparent;
}

html.dark .explCollapseButton.noExpl:hover {
  color: #626262;
}

.cm-problems-gutter svg,
.clickable {
  cursor: pointer;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

html.dark .gutter {
  background-color: #222;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}